.navbar-main-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 50px; */
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.navbar-main-container-top{
    height: 40px;
    width: 100%;
    background-color: #ff3b30;
    color: #ffffff;
    padding: 10px;
    font-weight: 700;
    overflow: hidden;
}
.navbar-main-container-bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
#scroll-text {
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow */
    
    display: inline-block;
    animation: scroll 50s ease-in-out infinite;
}
#scroll-text-noannouncement{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
}
.announcement-text{
    margin-top: 3px;
}
@keyframes scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
 
#scroll-text:hover {
    animation-play-state: paused; /* Pause animation on hover */
}
.navbar-menu-container {
    height: 300px;
    overflow: auto;
}
.did-menu-container {
    width: 285px;
    height: 100px;
    overflow: auto;
}
.navbar-sidebar-head-rht{
    font-size: 12px;
    color: #b3b1b1;
    word-break: break-all;
}
.navbar-logo-img {
    width: 150px;
}
.white-label-logo{
    width: 104px;
    margin-left: 22px;
}
 
.navbar-lft-container {
    width: 40%;
}
 
.navbar-right-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 60%;
    user-select: none;
    padding: 5px;
    margin-top: 5px;
}
 
.navbar-right-sub-container {
    padding: 5px 5px 0px 5px;
}
 
.navbar-right-sub-container-clock {
    padding: 12px 10px 0px 10px;
    width: 170px;
}
 
.navbar-icons {
    margin-top: 6px;
    color: #3e98c7;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
}
 
.navbar-menu {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 5px;
    padding: 5px 5px 10px 5px;
    overflow: hidden;
 
 
}
 
.navbar-menu-icon {
    color: #3e98c7;
    font-size: 30px;
    padding: 10px 10px 5px 30px;
    cursor: pointer;
}
.navbar-menu-icon-text {
    text-align: center;
}
 
.navbar-menu-sub-div {
    background-color: #ffffff;
    padding-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
}
 
.navbar-menu-sub-div:hover {
 
    background-color: rgb(233 236 239);
    color: #282530;
    padding-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
}
 
.navbar-menu-sub-div:hover .navbar-menu-icon {
    color: #3e98c7;
    padding: 10px 10px 5px 30px;
}
 
.navbar-menu-header {
    padding: 8px 8px 2px 16px;
    font-weight: 600;
}
 
 
.navbar-timer {
    color: #3e98c7;
}
 
.navbar-profile {
    height: 40px;
    width: 40px;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 50%;
    background-color: #3e98c7;
}
 
.navbar-notification {
    position: relative;
 
}
 
.navbar-notification-icon {
    padding: 6px;
    color: #fff;
    height: 50px;
    width: 50px;
}
 
.toast-container {
    z-index: 10;
    padding: 50px;
}
 
.navbar-Offcanvas-Body {
    padding: 0px !important;
}
 
.navbar-side-bar-body {
    flex-grow: 4;
    padding: 1rem 1rem;
    overflow-y: auto;
    padding: 5px 5px !important;
    background: rgb(84 166 224 / 10%);
    margin: 3px;
    height: 100px !important;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
 
}
 
.navbar-side-bar-body-unread {
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
 
.navbar-side-bar-title {
    color: #39ace9;
 
}
 
.navbar-side-bar-header {
    background-color: #3e98c7;
    color: #ffffff;
}
 
.navbar-side-bar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 4px;
}
 
.navbar-side-bar-container1 {
    display: flex;
    flex-direction: row;
    width: 80%;
}
 
.navbar-side-bar-container1-sub {
    width: 90% !important;
}
 
.navbar-side-bar-container2 {
    display: flex;
    flex-direction: row;
    width: 20%;
}
 
.navbar-side-bar-container2-sub {
    width: 10% !important;
}
 
.navbar-side-bar-button {
    height: 4px;
    width: 4px;
    border-radius: 4px;
    display: flex;
    padding: 13px;
    font-size: 15px;
    align-items: center;
    justify-content: center;
     margin-right: 5px;
}
 
.navbar-side-bar-close {
    background: rgb(170 50 50 / 10%);
    color: #d26565;
 
}
.navbar-notification-head{
    font-size: 15px;
}
.navbar-side-bar-close:hover {
    background: rgb(255 0 0 / 26%);
    color: #ea4949;
}
 
.navbar-side-bar-accept {
    background: rgb(50 170 82 / 10%);
    color: #32AA52;
 
}
 
.navbar-side-bar-accept:hover {
    background: rgb(57 159 84 / 45%);
    color: #368d4d;
}
 
.navbar-side-bar-view {
    background: rgb(255 153 0 / 10%);
    color: #FF9900;
 
}
 
.navbar-side-bar-view:hover {
    background: rgb(255 153 0 / 34%);
    color: #ff9900;
}
 
.navbar-side-bar-tail {
    background: rgb(255 255 255 / 98%);
    padding: 14px;
    height: 88px;
    width: 120px;
 
}
 
.navbar-side-bar-message {
    padding: 4px;
    color: #b3b1b1;
    height: 30px;
    word-wrap: break-word;
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
   
  
   
}
.navbar-side-bar-message-popover {
    word-wrap: break-word;
    padding: 4px;
    color: #b3b1b1;
}
.navbar-side-bar-message-head{
    background-color: #3e98c7 !important;
    color:#fff !important
}
 
.navbar-notification-nodata {
    width: 200px;
    text-align: center;
    margin-top: 200px;
    margin: auto;
    margin-top: 150px;
    background-color: #f0f2f5;
    padding: 6px;
    border-radius: 10px;
    color: #6c777d;
    
}
.navbar-sidebar-body1-subdiv{
    height: 100%;
}
.navbar-sidebar-body1 {
    height: 95%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
 
}
 
.navbar-sidebar-body2 {
    height: 5%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin: 10px;
 
}
 
 
.navbar-close{
    background: #3e98c7;
    cursor: pointer;
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 0px;
    font-size: 30px;
}
.navbar-whatsapp{
    width: 65px;
    height: 45px;
}
.navbar-icons-active{
    margin-top: 4px;
    background: #3e98c7;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
}
.navbar-profile-main{
    padding: 3px;
}
.navbar-script{
    width: 70px;
    border-radius: 100%;
    max-width: 100%;
    
}
.navbar-clearall {
    background-color: rgb(241 100 100) !important;
    color: #fff !important;
 
}
.navbar-clearal:hover {
     background-color: rgb(242, 52, 52) !important;
     color: #fff !important;
  
  }
  .ava-bgclr{
    background-color: #0cc300;
}
.parse-bgclr{
    background-color: rgb(241 100 100);
}
 
.parse-ylo-bgclr{
    background-color:#f59930
}
 
.parse-bk-clr{
    width: auto;
    /* min-width: 16px; */
    border-radius: 77px;
    padding: 6px;
    height: 30px;
    color: #fdfdfd;
    margin-top: 14px;
    font-weight: 600;
}
 
 
.navbar-profile:hover{
    cursor: pointer;
}
.navbar-notification:hover{
    cursor: pointer;
}
 
.navbar-right-sub-containers{
    padding: 5px 5px 0px 5px;
 
}
.left_logoimge:hover{
cursor: pointer;
}
 
.parse-txt-btn {
    width: auto;
    min-width: 0; /* Set min-width to 0 */
    border-radius: 77px;
    padding: 3px 13px; /* Adjust padding as needed */
    height: 30px;
    color: #fdfdfd;
    margin-top: 14px;
    font-weight: 600;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden;
    display: flex;
    flex-direction: row; /* Hide overflow content */
  }
 
 
  @media screen and (max-width:768px) {
    .mobile_hide {
        display: none !important;
    }
 
    .page-main-container {
        width: 100%;
    }
 
    .navbar-right-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: auto !important;
        user-select: none;
    }
 
    .leads-main-exist {
        width: 100% !important;
    }
 
    .leads-main-exist {
        width: 100% !important;
    }
 
    .Customform-body {
        grid-template-columns: auto !important;
    }
 
    .content {
        padding-right: 0px !important;
    }
 
    .desktop_hide {
        display: none;
    }
 
    .end_oncall_action_nav {
        z-index: 100;
    }
 
    .end_oncall_action_nav .red {
        background: #E43554;
        position: absolute;
        bottom: 30px !important;
        margin-bottom: 20px !important;
        color: #fff;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        padding: 13px;
        margin: 10px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
    }
 
    /* .desktop_hide_ {
        display: none;
    } */
}
@media screen and (max-width:767px) {
    .mobile_hide {
        display: none !important;
    }
 
    .page-main-container {
        width: 100%;
    }
 
    .leads-sidebar {
        width: auto !important;
    }
 
 
    .leads-main-exist {
        width: 100% !important;
    }
 
    .leads-main-exist {
        width: 100% !important;
    }
 
    .Customform-body {
        grid-template-columns: auto !important;
    }
 
    .content {
        padding-right: 0px !important;
    }
 
    .desktop_hide {
        display: none;
    }
 
    .end_oncall_action_nav {
        z-index: 100;
    }
 
    .end_oncall_action_nav .red {
        background: #E43554;
        position: absolute;
        bottom: 30px !important;
        margin-bottom: 20px !important;
        color: #fff;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        padding: 13px;
        margin: 10px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
    }
 
    /* .desktop_hide_ {
        display: none;
    } */
 
}
 
@media screen and (max-width:640px) {
    .mobile_hide {
        display: none !important;
    }
 
    .desktop_hide {
        display: none;
    }
 
    /* .desktop_hide_ {
        display: none;
    } */
}
 
@media screen and (max-width:577px) {
 
    .mobile_hide {
        display: none !important;
    }
 
    .desktop_hide {
        display: none;
    }
 
    /* .desktop_hide_ {
        display: none;
    } */
 
}
 
@media screen and (max-width:480px) {
    .mobile_hide {
        display: none !important;
    }
 
    .desktop_hide {
        display: none;
    }
    .navbar-right-sub-container {
        padding: 11px 0px 0px 10px;
    }
    
 
}